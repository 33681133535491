
import type { FetchError } from 'ofetch'
import type { Benefit } from '~/services/benefit/benefitService'
import type { PrimarySubscription } from '~/services/subscription/subscriptionService'

import type {
  SubscriptionBoxFeedback,
  SubscriptionBoxEdition,
  SubscriptionBoxOrder,
  SubscriptionBoxProduct,
  SubscriptionBoxAdditionalProduct,
} from '~/services/subscription/subscriptionBoxOrder/subscriptionBoxOrderService'

import CustomerService from '~/services/customer/customerService'
import SubscriptionBoxOrderService from '~/services/subscription/subscriptionBoxOrder/subscriptionBoxOrderService'

export async function useOrderData() {
  const route = useRoute()
  const notifications = useNotifications()

  const orderId = route.params["orderId"]

  const { data, pending, error } = await useAsyncData(() => {
    return Promise.all([
      SubscriptionBoxOrderService.findOrder(parseInt(orderId as string)),
      SubscriptionBoxOrderService.findEdition(parseInt(orderId as string)),
      SubscriptionBoxOrderService.findFeedback(parseInt(orderId as string))
        .catch((error: FetchError) => {
          if (error.status == 404) {
            return null
          } else {
            throw error
          }
        }),
      SubscriptionBoxOrderService.findProducts(parseInt(orderId as string))
        .catch((error: FetchError) => {
          if (error.status == 404) {
            return null
          } else {
            throw error
          }
        }),
      SubscriptionBoxOrderService.findAdditionalProducts(parseInt(orderId as string))
        .catch((error: FetchError) => {
          if (error.status == 404) {
            return null
          } else {
            throw error
          }
        }),
      CustomerService.findSelfPrimarySubscription()
        .catch((error: FetchError) => {
          if (error.status == 404) {
            return null
          } else {
            throw error
          }
        }),
      CustomerService.findSelfBenefits()
        .catch((error: FetchError) => {
          if (error.status == 404) {
            return []
          } else {
            throw error
          }
        }),
    ])
  })

  reportOnError(error, 'Failed to load subscription box order data')

  const loaded = computed(() => !pending.value)
  const order = computed(() => data.value?.[0] as SubscriptionBoxOrder | null)
  const edition = computed(() => data.value?.[1] as SubscriptionBoxEdition | null)
  const feeback = computed(() => data.value?.[2] as SubscriptionBoxFeedback | null)
  const products = computed(() => data.value?.[3] as SubscriptionBoxProduct[] | null)
  const additionalProducts = computed(() => data.value?.[4] as SubscriptionBoxAdditionalProduct[] | null)
  const primarySubscription = computed(() => data.value?.[5] as PrimarySubscription | null)
  const benefits = computed(() => (data.value?.[6] ?? []) as Benefit[])
  const hasFeedback = computed(() => {
    return feeback.value != null
  })

  const dataError = error.value

  if (dataError) {
    console.error(dataError)

    notifications.error(`Falha ao carregar informações da box`)
  }

  return {
    loaded,
    order,
    edition,
    feeback,
    products,
    additionalProducts,
    hasFeedback,
    primarySubscription,
    benefits
  }
}